import React from "react"
const Footer = () => {
  return (
    <div className="py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div>
              <h2 className="text-xl font-light tracking-tight text-slate-100">
                Homestudy OÜ
              </h2>
              <p className="mt-4 leading-7 font-light text-sm text-slate-200">
                A. Lauteri 3, 10114 Tallinn. Estonia 🇪🇪
              </p>
              <p className="mt-4 leading-7 font-light text-sm text-slate-200">
                Alauksta 13, Rīga. Latvia 🇱🇻
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              <div className="rounded-2xl  ">
                <h3 className="text-base font-semibold leading-7 text-slate-100">
                  Say hello
                </h3>
                <dl className="mt-3 space-y-1 text-sm leading-6 text-slate-300">
                  <div>
                    <dt className="sr-only">Email</dt>
                    <dd>
                      <a
                        className="font-semibold text-slate-200"
                        href="mailto:kalvis@tautapp.co"
                      >
                        Kalvis@tautapp.co
                      </a>
                    </dd>
                  </div>
                  <div className="mt-1">
                    <dt className="sr-only">Phone number</dt>
                    <dd>+371 2923 7113</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <p
          className="mt-10 text-sm
         leading-7 text-slate-400"
        >
          © 2023 Homestudy OÜ. All rights reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer
