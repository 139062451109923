import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FirstSection from "../components/firstSection"
import AboutusSection from "../components/aboutusSection"
import HeroDescSection from "../components/heroDescSection"
import Faster10x from "../components/Faster10x"
import { scroller, Element } from "react-scroll"
import FreeHosting from "../components/FreeHosting"
import SpeedTest from "../components/SpeedTest"
import HeroTop from "../components/HeroTop"
import HeadlessCMS from "../components/HeadlessCMS"
import SecuritySection from "../components/SecuritySection"
import TechnicalSEO from "../components/TechnicalSEO"
import TechstackSection from "../components/TechstackSection"
import lightlogo from "../images/lightlogo.png"
import { content } from "../components/content"
import Footer from "../components/footer"
import { Link } from "gatsby"

const IndexPage = () => {
  const lang = "en"
  const scrollToContact = () => {
    scroller.scrollTo("contactSection", {
      duration: 800, // Duration of the scroll animation in milliseconds
      smooth: "easeInOutQuart", // Type of easing for the scroll animation
    })
  }
  const scrollToHeroDesc = () => {
    scroller.scrollTo("heroDescSection", {
      duration: 800, // Duration of the scroll animation in milliseconds
      smooth: "easeInOutQuart", // Type of easing for the scroll animation
    })
  }
  const [showNavbar, setShowNavbar] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset

      // Adjust the scrollOffset value as needed
      const scrollOffset = 300

      if (scrollPosition > scrollOffset) {
        setShowNavbar(true)
      } else {
        setShowNavbar(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Layout showNavbar={showNavbar} scrollToContact={() => scrollToContact()}>
      <SEO title="Home" />

      <HeroTop
        data={content.heroTop.filter(section => section.lang === lang)[0]}
        scrollToContact={() => scrollToContact()}
        scrollToHeroDesc={() => scrollToHeroDesc()}
      />

      <Element name="heroDescSection">
        <HeroDescSection
          data={
            content.heroDescSection.filter(section => section.lang === lang)[0]
          }
        />
      </Element>
      <FirstSection
        data={content.firstSection.filter(section => section.lang === lang)[0]}
      />
      <Faster10x
        data={content.faster10x.filter(section => section.lang === lang)[0]}
      />
      <HeadlessCMS
        data={content.headlessCMS.filter(section => section.lang === lang)[0]}
      />
      <SecuritySection
        data={content.security.filter(section => section.lang === lang)[0]}
      />
      <TechnicalSEO
        data={content.technicalSEO.filter(section => section.lang === lang)[0]}
      />
      <TechstackSection
        data={content.techStack.filter(section => section.lang === lang)[0]}
      />

      <FreeHosting
        scrollToContact={() => scrollToContact()}
        data={content.pricing.filter(section => section.lang === lang)[0]}
      />
      <SpeedTest
        data={content.speedtest.filter(section => section.lang === lang)[0]}
      />
      <Element name="contactSection">
        <AboutusSection
          data2={content.aboutUs.filter(section => section.lang === lang)[0]}
          data1={content.contact.filter(section => section.lang === lang)[0]}
        />
      </Element>
      <Footer />
    </Layout>
  )
}

export default IndexPage
